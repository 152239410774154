import { useEffect, useState } from "react";
import AuthAction from "../../Actions/auth.service";
import ConnectionCard from "../../Components/ConnectionCard";
import { hideLoader, showLoader } from "../../utils/helpers";

const Connections = () => {
  const [connections, setConnections] = useState([]);
  const [allConnections, setAllConnections] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("all");
  const [filteredConnections, setFilteredConnections] = useState([]);

  useEffect(() => {
    getConnections();
  }, []);

  useEffect(() => {
    showLoader();
    if (selectedType == "all") {
      setFilteredConnections(allConnections);
      setConnections(allConnections);
      hideLoader();
    } else {
      const temp = allConnections.filter(
        (connection) => connection.connection_type == selectedType
      );
      setFilteredConnections(temp);
      setConnections(temp);
      hideLoader();
    }
    setSearch("");
  }, [allConnections, selectedType]);

  const getConnections = () => {
    showLoader();
    AuthAction.getConnections()
      .then((res) => {
        setConnections(res);
        setAllConnections(res);
        setFilteredConnections(res);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err, "Error while fetching Connections list");
      });
  };

  const handleChangeType = (type) => {
    setSelectedType(type);
  };

  const handleSearchChange = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    const temp = connections.filter((val) =>
      val.connection_id?.fullName
        .toLowerCase()
        .includes(searchVal.toLowerCase())
    );
    setFilteredConnections(temp);
  };
  console.log(connections, "check connections");
  return (
    <>
      <div>
        <h4 className="my-connection-h4">My Connections</h4>
        <div className="connections-type event_lists_div">
          <ul className="event_lists">
            <li
              className={`${selectedType == "all" && "selected"}`}
              onClick={(e) => handleChangeType("all")}
            >
              All
            </li>
            <li
              className={`${selectedType == "Friend" && "selected"}`}
              onClick={(e) => handleChangeType("Friend")}
            >
              Friend
            </li>
            <li
              className={`${selectedType == "Family" && "selected"}`}
              onClick={(e) => handleChangeType("Family")}
            >
              Family
            </li>
            <li
              className={`${selectedType == "Colleague" && "selected"}`}
              onClick={(e) => handleChangeType("Colleague")}
            >
              Colleague
            </li>
            <li
              className={`${selectedType == "Neighbour" && "selected"}`}
              onClick={(e) => handleChangeType("Neighbour")}
            >
              Neighbour
            </li>
            <li
              className={`${selectedType == "Other" && "selected"}`}
              onClick={(e) => handleChangeType("Other")}
            >
              Other
            </li>
          </ul>
          <div className="connection-search-div">
            <input
              type="text"
              className="form-control"
              placeholder="Search.."
              onChange={(e) => handleSearchChange(e)}
              value={search}
            />
          </div>
        </div>
        <div className="connections-div text-center">
          {filteredConnections.length > 0 ? (
            <ConnectionCard
              connections={filteredConnections}
              getConnections={getConnections}
            />
          ) : (
            <h5>No Connections Found</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default Connections;
