import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AuthAction from "../../Actions/auth.service";
import {
  ShowImage,
  haveValue,
  hideLoader,
  showLoader,
} from "../../utils/helpers";
import { Link } from "react-router-dom";
import eventService from "../../Actions/event.service";

const AddFromConnectionModal = (props) => {
  const { showModal, handleCloseModal, createdBy, eventId } = props;

  const [allConnections, setAllConnections] = useState([]);
  const [connections, setConnections] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedConnections, setSelectedConnections] = useState(new Map());

  useEffect(() => {
    showLoader();
    AuthAction.getConnections()
      .then((res) => {
        setAllConnections(res);
        setConnections(res);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err, "Error while fetching connections");
      });
  }, []);

  const handleSelectConnection = (connection) => {
    setSelectedConnections((prevState) => {
      const tempMap = new Map(prevState);
      if (tempMap.has(connection._id)) {
        tempMap.delete(connection._id);
      } else {
        tempMap.set(connection._id, {
          firstName: connection.connection_id?.firstName,
          lastName: connection.connection_id?.lastName,
          phoneNumber: connection.connection_id?.phoneNumber,
          email: connection.connection_id?.email,
        });
      }
      return tempMap;
    });
  };

  const handleSendInvite = async () => {
    showLoader();

    const waitingPromises = Array.from(selectedConnections.entries()).map(
      ([key, vals]) => {
        var payload = {
          name: vals?.firstName + " " + vals?.lastName,
          email: vals.email,
          phoneNumber: `${vals.phoneNumber}`,
          createdBy: createdBy,
          eventId: eventId,
        };
        return eventService.invite(payload);
      }
    );
    try {
      await Promise.all(waitingPromises);
      hideLoader();
      handleCloseModal();
      console.log("All invitations sent successfully.");
    } catch (error) {
      hideLoader();
      handleCloseModal();
      console.error("Failed to send some invitations:", error);
    }
  };

  const handleSearchChange = (e) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    const temp = allConnections.filter((val) =>
      val.connection_id?.fullName
        .toLowerCase()
        .includes(searchVal.toLowerCase())
    );
    setConnections(temp);
  };

  return (
    <>
      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup add-connection-modal"
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header className="faviconadded" closeButton>
          Select From Connections
        </Modal.Header>
        <div className="connection-search-div">
          <input
            type="text"
            className="form-control"
            placeholder="Search.."
            onChange={(e) => handleSearchChange(e)}
            value={search}
          />
        </div>
        <Modal.Body className="text-center">
          {connections.map((connection) => {
            if (haveValue(connection?.connection_id)) {
              return (
                <div
                  className="d-flex connection-card-div cursor-pointer"
                  onClick={(e) => handleSelectConnection(connection)}
                >
                  <div className="checkbox-img-div">
                    {selectedConnections.has(connection._id) ? (
                      <img src="/images/SelectedCheckbox.svg" />
                    ) : (
                      <img src="/images/UnSelectedCheckbox.svg" />
                    )}
                  </div>
                  <div className="connection-card-img">
                    <img
                      className="profimnew"
                      src={ShowImage(
                        connection.connection_id?.profilePhoto,
                        connection.connection_id?.fullName
                      )}
                      alt="img"
                    />
                  </div>
                  <div className="w-100">
                    <h5 className="text-start connection-card-name">
                      {connection.connection_id?.fullName}
                    </h5>
                    <div>
                      <div className="connection-card-status">
                        <p>
                          {haveValue(connection.connection_type)
                            ? connection.connection_type
                            : "Not mentioned"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 savebtn">
            <button
              disabled={selectedConnections.size == 0}
              className="btn-pink"
              onClick={handleSendInvite}
            >
              Send Invite
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddFromConnectionModal;
