import { useEffect, useState } from "react";
import AuthAction from "../../Actions/auth.service";
import NotificationCard from "../../Components/Notification/NotificationCard";
import { hideLoader, showLoader } from "../../utils/helpers";

const Notification = () => {
  const [timePeriod, setTimePeriod] = useState("Today");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const elementTest = document.querySelector(".notification-tabs");

    elementTest.scrollLeft = -1000;
  }, []);

  useEffect(() => {
    const selectedDate = new Date();
    showLoader();
    AuthAction.getConnectionsDates({ date: selectedDate, period: timePeriod })
      .then((res) => {
        setEvents(res);
        hideLoader();
      })
      .catch((err) => {
        console.log(err, "Error in fetching Connections Dates");
        hideLoader();
      });
  }, [timePeriod]);

  return (
    <>
      <div>
        <h4 className="my-connection-h4">Notifications</h4>
        <div className="amit-test">
          <div className="notification-tabs">
            <div
              onClick={(e) => setTimePeriod("Today")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "Today" && "selected"
              }`}
            >
              <h5>Today</h5>
            </div>
            <div
              onClick={(e) => setTimePeriod("Tomorrow")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "Tomorrow" && "selected"
              }`}
            >
              <h5>Tomorrow</h5>
            </div>
            <div
              onClick={(e) => setTimePeriod("ThisWeek")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "ThisWeek" && "selected"
              }`}
            >
              <h5>This Week</h5>
            </div>
            <div
              onClick={(e) => setTimePeriod("NextWeek")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "NextWeek" && "selected"
              }`}
            >
              <h5>Next Week</h5>
            </div>
            <div
              onClick={(e) => setTimePeriod("ThisMonth")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "ThisMonth" && "selected"
              }`}
            >
              <h5>This Month</h5>
            </div>
            <div
              onClick={(e) => setTimePeriod("NextMonth")}
              className={`time-period-div cursor-pointer ${
                timePeriod == "NextMonth" && "selected"
              }`}
            >
              <h5>Next Month</h5>
            </div>
          </div>
        </div>
        {events.length > 0
          ? events.map((event) => <NotificationCard event={event} />)
          : "No Event"}
      </div>
    </>
  );
};

export default Notification;
