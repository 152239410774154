import React from "react";
import settings from "../../Config/Config";
import { ShowImage, haveValue } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

function UserPerviewList(props) {
  const { usersData, eventData } = props;

  const navigate = useNavigate();

  const handleViewContributors = () => {
    navigate(props?.navigateTo);
  };

  return (
    <>
      {usersData != null ? (
        <>
          <div className="country-price">
            <ul className="perviewlist">
              {usersData?.images?.map((item) => (
                <>
                  <li>
                    {" "}
                    <img
                      src={ShowImage(item?.image, item?.fullName)}
                      alt={item?.fullName}
                    />
                  </li>
                </>
              ))}
              {usersData?.hasMore && haveValue(props?.navigateTo) && (
                <a
                  href={undefined}
                  className="see-more-contributors"
                  onClick={handleViewContributors}
                >
                  See More...
                </a>
              )}
            </ul>
            <h6 className="allusers-text">{usersData?.label}</h6>
          </div>
        </>
      ) : null}
    </>
  );
}

export default UserPerviewList;
