import { Button, Modal } from "react-bootstrap";
import {
  ShowImage,
  haveValue,
  hideLoader,
  showLoader,
} from "../../utils/helpers";
import { useState } from "react";
import AuthAction from "../../Actions/auth.service";

const ConnectionCard = (props) => {
  const { connections, getConnections } = props;

  const defaultStatusChange = {
    id: "",
    showModal: false,
    type: "",
  };
  const [statusChange, setStatusChange] = useState(defaultStatusChange);

  const handleStatusChange = (connection) => {
    setStatusChange({
      id: connection?._id,
      showModal: true,
      type: connection.connection_type,
    });
  };

  const handleCloseModal = () => {
    setStatusChange((prev) => ({ ...prev, showModal: false }));
  };

  const handleChangeLabel = (connectionType) => {
    const temp = { ...statusChange };
    temp.type = connectionType;
    showLoader();
    AuthAction.updateConnection(temp)
      .then((res) => {
        setStatusChange(defaultStatusChange);
        getConnections();
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err, "Error in Update Connection");
      });
  };

  return (
    <>
      {connections.map((connection) => {
        if (haveValue(connection?.connection_id)) {
          console.log(connection?.fullName, "check full names here");
          return (
            <div className="d-flex connection-card-div">
              <div className="connection-card-img">
                <img
                  className="profimnew"
                  src={ShowImage(
                    connection.connection_id?.profilePhoto,
                    connection.connection_id?.fullName
                  )}
                  alt="img"
                />
              </div>
              <div className="w-100">
                <h5 className="text-start connection-card-name">
                  {connection.connection_id?.fullName}
                </h5>
                <div>
                  {haveValue(connection.connection_type) ? (
                    <div className="connection-card-status">
                      <p>{connection.connection_type}</p>
                      <svg
                        className="cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        onClick={(e) => handleStatusChange(connection)}
                      >
                        <path
                          d="M18 10.0001L14 6.00006M2.5 21.5001L5.88437 21.124C6.29786 21.0781 6.5046 21.0551 6.69785 20.9925C6.86929 20.937 7.03245 20.8586 7.18289 20.7594C7.35245 20.6476 7.49955 20.5005 7.79373 20.2063L21 7.00006C22.1046 5.89549 22.1046 4.10463 21 3.00006C19.8955 1.89549 18.1046 1.89549 17 3.00006L3.79373 16.2063C3.49955 16.5005 3.35246 16.6476 3.24064 16.8172C3.14143 16.9676 3.06301 17.1308 3.00751 17.3022C2.94496 17.4955 2.92198 17.7022 2.87604 18.1157L2.5 21.5001Z"
                          stroke="#E31B54"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  ) : (
                    <p
                      className="add-label red-color cursor-pointer"
                      onClick={(e) => handleStatusChange(connection)}
                    >
                      + Add Label
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      })}
      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup"
        show={statusChange.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header className="faviconadded" closeButton>
          Select Label
        </Modal.Header>
        <Modal.Body className="text-center">
          <p
            className="relation_p"
            onClick={(e) => handleChangeLabel("Friend")}
          >
            Friend{" "}
            {statusChange.type == "Friend" && <img src="/images/TickRed.svg" />}
          </p>
          <p
            className="relation_p"
            onClick={(e) => handleChangeLabel("Family")}
          >
            Family{" "}
            {statusChange.type == "Family" && <img src="/images/TickRed.svg" />}
          </p>
          <p
            className="relation_p"
            onClick={(e) => handleChangeLabel("Colleague")}
          >
            Colleague{" "}
            {statusChange.type == "Colleague" && (
              <img src="/images/TickRed.svg" />
            )}
          </p>
          <p
            className="relation_p"
            onClick={(e) => handleChangeLabel("Neighbour")}
          >
            Neighbour{" "}
            {statusChange.type == "Neighbour" && (
              <img src="/images/TickRed.svg" />
            )}
          </p>
          <p className="relation_p" onClick={(e) => handleChangeLabel("Other")}>
            Other{" "}
            {statusChange.type == "Other" && <img src="/images/TickRed.svg" />}
          </p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ConnectionCard;
