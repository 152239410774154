import { useEffect, useState } from "react";
import { ShowImage, haveValue } from "../../utils/helpers";
import moment from "moment";

const NotificationCard = (props) => {
  const { event } = props;
  const [eventData, setEventData] = useState({
    type: "",
    date: "",
  });
  useEffect(() => {
    if (haveValue(event.dobFormatted) && event.is_birthday) {
      console.log(event.dobFormatted, "event.dobFormatted");
      setEventData({
        type: "Birthday Celebration",
        date: moment(event.dobFormatted).format("MMM D, YYYY"),
      });
    } else if (haveValue(event.anniversaryMonthDay) && event.is_anniversary) {
      console.log(event.anniversaryFormatted, "event.anniversaryFormatted");
      setEventData({
        type: "Anniversary Celebration",
        date: moment(event.anniversaryFormatted).format("MMM D, YYYY"),
      });
    }
  }, [event]);

  console.log(eventData, "check eventData here");

  return (
    <>
      <div className="d-flex connection-card-div">
        <div className="position-relative">
          <img
            className="notification-profile-pic"
            src={ShowImage(
              event.connectionDetails?.profilePhoto,
              event.connectionDetails?.fullName
            )}
            alt="img"
          />
          <img
            className="celebration-icon"
            src={`/images/${
              eventData.type == "Birthday Celebration"
                ? "BirthdayPink"
                : "AnniversaryPink"
            }.svg`}
          />
        </div>
        <div className="w-100 notification-info">
          <h5 className="text-start">
            {event.connectionDetails?.fullName}'s {eventData.type}
          </h5>
          <p>{eventData.date}</p>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
