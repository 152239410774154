import React, { useEffect, useState, useContext } from "react";
import Sparkle from "../../Animations/Sparkle";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  isValidDate,
  ShowImage,
  hideLoader,
  showLoader,
  withBackendUrl,
  dateInPast,
  haveValue,
  getDateLocal,
} from "../../utils/helpers";
import UserPerviewList from "../../Components/UserPerviewList";
import { AuthContext } from "../../Context/AuthContext";
import AuthAction from "../../Actions/auth.service";
import { ThemeContext } from "../../hooks/useThemeContext";

function RecipientList() {
  const navigate = useNavigate();
  const { isLoggedIn, loggedInUserId, setIsLoggedIn, setIsSidebarVisible } =
    useContext(AuthContext);
  const { setBackUrlContext } = useContext(ThemeContext);
  const [myGiftInfo, setMyGiftInfo] = useState([]);

  useEffect(() => {
    if (haveValue(isLoggedIn) && isLoggedIn) {
      showLoader();
      AuthAction.getGiftData()
        .then((res) => {
          setMyGiftInfo(res);
          hideLoader();
        })
        .catch((err) => {
          console.log(err);
          hideLoader();
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);
            navigate("/");
          }
        });
    }
    setBackUrlContext(`home`);
    localStorage.removeItem("backToEvent");
    localStorage.setItem("backToRecList", true);
  }, [isLoggedIn]);

  return (
    <>
      <div className="giftlistbar">
        {myGiftInfo.length > 0 ? (
          myGiftInfo.map((item, i) => {
            return (
              <>
                {console.log(
                  new Date() >
                    new Date(getDateLocal(item?.eventStart, item?.eventTime)) &&
                    haveValue(item?.giftCardPassCode) &&
                    haveValue(item?.giftCardExpiry) &&
                    haveValue(item?.giftCardId),
                  "CONDITIONS MET HERE"
                )}
                {new Date() >
                  new Date(getDateLocal(item?.eventStart, item?.eventTime)) &&
                haveValue(item?.giftCardPassCode) &&
                haveValue(item?.giftCardExpiry) &&
                haveValue(item?.giftCardId) ? (
                  <>
                    <div className="Home-component EventPartially Participantbar ">
                      <Sparkle />
                      <div>
                        <div className="usergiftlist avatar-img">
                          <img
                            src={ShowImage(item?.photo, item?.name)}
                            alt="img"
                          />
                          <div className="userdetail">
                            <h4>
                              <span>{item?.name}</span>'s {item?.occasionId}
                            </h4>
                            {/* <h6 className="date">  {moment(item?.eventStart).format("LL")} </h6> */}
                            <h6 className="date">
                              {" "}
                              {moment(
                                getDateLocal(item?.eventStart, item?.eventTime)
                              ).format("LL")}{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <UserPerviewList />
                      <div className="btnsubmit btncontribute">
                        <Link
                          to={`/recipient/${item?._id}`}
                          type="button"
                          className="btngray"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })
        ) : (
          <div className="no-giftbar">
            <img src="./images/NoGift.svg" alt="nogift" />
            <p> No Gifts Recieved </p>
          </div>
        )}
      </div>
    </>
  );
}

export default RecipientList;
