import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsFacebook, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { Modal } from "react-bootstrap";

function Footer() {
  const handleInstagram = async () => {
    window.open("https://www.instagram.com/social.wegroup4/", "_blank");
  };
  const handleFacebook = async () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61552328150885",
      "_blank"
    );
  };

  const handleTwitter = () => {
    window.open("https://twitter.com/social_weGroup4");
  };
  const handleYoutube = () => {
    window.open("https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w");
  };

  return (
    <>
      <div className="Footer">
        <div className="foot-logo">
          <Link to="/">
            <img src="/images/wegroup-white.svg" alt="logo" />
          </Link>
        </div>
        <ul className="foot-links">
          <li>
            <NavLink to="/landingaboutus">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/login">Login / Signup</NavLink>
          </li>
          {/* <li><NavLink to='/'>Help</NavLink></li> */}
          <li>
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
          {/* <li><NavLink to='/works'>How it works</NavLink></li> */}
          <li>
            <NavLink to="/privacy">Privacy Policy</NavLink>
          </li>
          <li>
            <NavLink to="/terms">Terms & Conditions</NavLink>
          </li>
        </ul>
        {/* <ul className='social-links'>
          <li><Link onClick={handleFacebook}><BsFacebook /></Link></li>
          <li><Link onClick={handleInstagram}><BsInstagram /></Link></li>
        </ul> */}
        <div className="contact-detailbar">
          {/* <ul>
            <li><b>Company Name:</b> Radipro Technologies India Pvt Ltd.</li>
            <li><b>Address:</b> NK-I/605, FF-2, Indirapuram, Ghaziabad, Uttar Pradesh, PIN-201014</li>
            <li><b>Email Id:</b> <a href="mailto:Sumeshk@radiprotech.com">Sumeshk@radiprotech.com</a></li>
            <li><b>Mobile :</b> <a href="tel:+91-7718880313">+91-7718880313</a></li>
          </ul> */}
        </div>
        <ul className="social-links">
          <li>
            {/* <div className="Leftbar" onClick={handleFacebook}> */}
            <a
              className="Leftbar"
              href="https://www.facebook.com/profile.php?id=61552328150885"
              target="_blank"
            >
              <img className="social-img" src="/images/Facebook.png" alt="fb" />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleInstagram}> */}
            <a
              className="Leftbar"
              href="https://www.instagram.com/social.wegroup4/"
              target="_blank"
            >
              <img
                className="social-img"
                src="/images/instagram.png"
                alt="instagram"
              />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleTwitter}> */}
            <a
              className="Leftbar"
              href="https://twitter.com/social_weGroup4"
              target="_blank"
            >
              <img
                className="social-img"
                src="/images/TwitterNew.png"
                alt="twitter"
              />
            </a>
            {/* </div> */}
          </li>
          <li>
            {/* <div className="Leftbar" onClick={handleYoutube}> */}
            <a
              className="Leftbar"
              href="https://www.youtube.com/channel/UCR4wt9OiHrhUtSDCe7kc97w"
              target="_blank"
            >
              <img
                className="social-img"
                src="/images/youtube.png"
                alt="twitter"
              />
            </a>
            {/* </div> */}
          </li>
          <li>
            <div className="Leftbar">
              <img
                className="social-img"
                src="/images/linkedin.png"
                alt="twitter"
              />
            </div>
          </li>
        </ul>
        <p className="note">
          © 2024 Radipro Technologies India Pvt Ltd. All rights reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
