import Agent from "../Services/RequestInstance";
import { ServerError } from "../utils/helpers";
import * as http from "../Services/http";
import { AuthContext } from "../Context/AuthContext";

import config from "../Config/Config";
import { useContext } from "react";

const BACKEND_URL = config.BACKEND_URL;

function loginSignup(payload, cb) {
  return http.post("/auth/loginSignUp", payload);
}

function saveDetails(payload, cb) {
  return http.patch("/auth/saveDetails", payload);
}

function verifyGoogleSignUp(payload, cb) {
  return http.post("/auth/verifyGoogleSignUp", payload);
}
function getFirebaseDetails(payload, cb) {
  return http.post("/auth/getFirebaseDetails", payload);
}
function getEventData(eventType, cb) {
  return http.get(`/auth/getEventData?eventType=${eventType}`);
}

function getUserData(payload, cb) {
  return http.get("/auth/getUserData");
}

function getGiftData(payload, cb) {
  return http.get("/auth/getGiftData");
}
function updateProfile(payload, cb) {
  return http.post("/auth/update-profile", payload);
}
function contactUsEmail(payload, cb) {
  return http.post("/auth/send-contact-email", payload);
}
function verifyEmail(payload, cb) {
  return http.post("/auth/verify-email", payload);
}
function addConnections(payload, cb) {
  return http.get("/auth/add/connections");
}
function getConnections(payload, cb) {
  return http.get("/auth/get/connections");
}
function updateConnection(payload, cb) {
  return http.post("/auth/update/connections", payload);
}
function getConnectionsDates(payload, cb) {
  return http.get("/auth/connections/dates", payload);
}

export default {
  loginSignup,
  saveDetails,
  verifyEmail,
  verifyGoogleSignUp,
  getFirebaseDetails,
  getEventData,
  getUserData,
  getGiftData,
  updateProfile,
  contactUsEmail,
  addConnections,
  getConnections,
  updateConnection,
  getConnectionsDates,
};
