import React, { useState, useEffect, useContext } from "react";
import Sparkle from "../../../Animations/Sparkle";
import DateTimeBoxOuter from "../../../Components/DateTimeBoxOuter/Index";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import MoneyCollectBox from "../../../Components/Dashboard/MoneyCollectBox/Index";
import AddParticipant from "../../../Components/Dashboard/AddParticipant/Index";
import AddGiftBox from "../../../Components/Dashboard/AddGiftBox/Index";
import AddTestimonialBox from "../../../Components/Dashboard/AddTestimonialBox/Index";
import EventAction from "./../../../Actions/event.service";
import moment from "moment";
import TestimonialAction from "../../../Actions/testimonial.service";
import contributionAction from "../../../Actions/contribution.service";
import { AuthContext } from "../../../Context/AuthContext";
import {
  hideLoader,
  isNotEmpty,
  isValidDate,
  ShowImage,
  showLoader,
  haveValue,
  getTotalContri,
  getDateLocal,
} from "../../../utils/helpers";
import EventPartiallyList from "./EventPartiallyList";
import { ThemeContext } from "../../../hooks/useThemeContext";
import { Button, Modal } from "react-bootstrap";
import Success from "./../../../Animations/Successfull";
import Form from "react-bootstrap/Form";
import { BsCardChecklist } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import EventCancelState from "../../../Components/EventCancelState/Index";
import { toast } from "react-toastify";
import UploadImage from "../../../Components/UploadMedia/UploadImage";
import imageCompression from "browser-image-compression";

function Organiser() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [eventTime, setEventTime] = useState("");
  const [eventStart, setEventStart] = useState("");
  const [testimonialList, setTestimonialList] = useState();
  const [showSave, setShowSave] = useState(false);
  const [roleCheck, setRoleCheck] = useState(true);
  const [showEditPic, setshowEditPic] = useState(false);
  const [audios, setAudios] = useState(0);
  const [photos, setPhotos] = useState(0);
  const [videos, setVideos] = useState(0);
  const [gif, setGif] = useState(0);
  const [text, setText] = useState(0);
  const [showdatetimeadded, setShowdatetimeadded] = useState(false);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const [showContributionSetting, setShowContributionSetting] = useState(false);
  const [scrollToAddPartDivOnToggle, setScrollToAddPartDivOnToggle] =
    useState(false);
  const [uploadedPic, setUploadedPic] = useState(null);

  const { showSuccessToast, showErrorToast, setBackUrlContext, setHide } =
    useContext(ThemeContext);
  const {
    eventData,
    setEventData,
    isLoggedInUserId,
    setIsLoggedIn,
    setIsSidebarVisible,
    loggedInUserId,
  } = useContext(AuthContext);

  // cancel event states
  const [CancelEventshow, EventsetShow] = useState(false);

  {
    /* event can't be cancelled */
  }
  const [showGiftButton, setShowGiftButton] = useState(true);
  const [showConfigureButton, setShowConfigureButton] = useState(true);
  const [addParticipantButton, setAddParticipantButton] = useState(true);
  const [EventCantshow, EventCantsetShow] = useState(false);
  const EventCantsethandleShow = () => EventCantsetShow(true);
  const [contributingList, setContributingList] = useState([]);
  const [alreadyContributed, setAlreadyContributed] = useState(false);
  const [joinedPart, setJoinedPart] = useState([]);
  const EventhandleShow = () => {
    if (eventData?.totalContri > 0) {
      EventCantsetShow(true);
    } else {
      EventsetShow(true);
    }
  };

  const handleClosedateadded = () => {
    setShowdatetimeadded(false);
    setShowContributionSetting(false);
    EventCantsetShow(false);
    EventsetShow(false);
    handleUpdate("");
  };

  const reloadEvent = () => {
    viewEvent();
  };

  useEffect(() => {
    setHide(false);
    localStorage.setItem("roleId", `${id}`);
    localStorage.setItem("backToEvent", true);
    setBackUrlContext("home");
    viewEvent();
    contributionsList();
    disableButtons();
  }, []);

  useEffect(() => {
    disableButtons();
    showLoader();
    if (scrollToAddPartDivOnToggle) {
      const element = document.getElementById(`AddParticipant`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      if (haveValue(roleCheck) && roleCheck) {
        let resultA = [];
        if (isLoggedInUserId(eventData?.createdBy?._id)) {
          localStorage.setItem("role", "organiser");
        } else if (isNotEmpty(eventData?.participants)) {
          const newFilter = Object.keys(eventData?.participants).reduce(
            (result, key) => {
              if (
                eventData?.participants[key]?.userId?._id.includes(
                  loggedInUserId
                )
              ) {
                localStorage.setItem("role", "participant");
              }
            },
            []
          );
        } else {
          navigate("/getStarted");
        }
        if (
          !window.location.pathname.startsWith(localStorage.getItem("role"))
        ) {
          navigate(`/${localStorage.getItem("role")}/${id}`);
        }
        if (isNotEmpty(eventData?.participants)) {
          const newFilter = Object.keys(eventData?.participants).reduce(
            (result, key) => {
              if (eventData?.participants[key].status.includes("joined")) {
                resultA.push(eventData?.participants[key]);
                setJoinedPart(resultA);
              }
            },
            []
          );
        }
        setRoleCheck(false);
      }
    }
    hideLoader();
  }, [eventData]);

  const viewEvent = (action = "initial") => {
    // showLoader();
    EventAction.viewEvent(id)
      .then((res) => {
        // hideLoader();
        res.scrollAction = action;
        setEventData(res);
        getTestimonials();
        console.log(res.eventStart, "HERE IS SETTING DATE");
        if (haveValue(res.eventStart) && haveValue(res.eventTime)) {
          var date = getDateLocal(res.eventStart, res.eventTime);
          setEventStart(moment(date).format("LL"));
          setEventTime(moment(date).format("h:mm a"));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);
          navigate("/");
        }
      });
  };
  const confirmOrder = () => {
    showLoader();
    EventAction.confirmOrder(id)
      .then((res) => {
        showSuccessToast("Event Confirmed");
        setEventData(res);
        getTestimonials();
        // setEventStart(moment(res.eventStart).format("LL"));
        var date = getDateLocal(res.eventStart, res.eventTime);
        setEventStart(moment(date).format("LL"));
        setEventTime(moment(date).format("h:mm a"));
        handleUpdate("");
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err);
        showErrorToast("Please try again later");
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);
          toast.dismiss();

          navigate("/");
        }
      });
  };
  const contributionPage = () => {
    handleClosedateadded();
    if (haveValue(eventData?.totalContri) && eventData?.totalContri > 0) {
      setShowContributionSetting(true);
    } else {
      if (isNotEmpty(eventData?.giftCardType)) {
        navigate(`/ContributionPage/${id}`);
      } else {
        showErrorToast("Please Choose Gift Card first.");
      }
    }
  };

  const updateDateAndTime = () => {
    let payload = {};
    payload["_id"] = id;
    const selectedDate = new Date(eventStart.concat(", ", eventTime));
    payload["eventRawDate"] = eventStart;
    payload["eventRawTime"] = eventTime;

    const utcTime = new Date(
      selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000
    );
    payload["eventStart"] = moment(utcTime).format("MMM D, YYYY");
    payload["eventTime"] = moment(utcTime).format("h:mm A");
    if (isValidDate(eventStart) && isNotEmpty(eventTime)) {
      showLoader();
      EventAction.updateEventById(payload)
        .then((res) => {
          hideLoader();
          setShowdatetimeadded(true);
          setShowSave(false);
          disableButtons();
        })
        .catch((err) => {
          console.log(err);
          setShowSave(false);
          hideLoader();
          if (err?.response?.data?.message == "Unauthorized User.") {
            localStorage.removeItem("token");
            setIsLoggedIn(false);
            setIsSidebarVisible(false);
            navigate("/");
          }
        });
    } else {
      setShowSave(false);
      showErrorToast("Please Select Both Date & Time");
    }
  };

  const handleUpdate = (type = "") => {
    viewEvent(type);
    if (type === "AddParticipant") {
      setScrollToAddPartDivOnToggle(true);
    } else {
      setScrollToAddPartDivOnToggle(false);
    }
  };

  const handleChangeDat = (e) => {
    setEventStart(e);
  };

  const handleChangeTim = (e) => {
    setEventTime(e);
  };

  const getTestimonials = () => {
    const answer_array = window.location.pathname.split("/");
    TestimonialAction.testimonialList(id, "all", "")
      .then((res) => {
        setTestimonialList(res.testimonials);
        setAudios(res.audioCount);
        setPhotos(res.imageCount);
        setVideos(res.videoCount);
        setGif(res.gifCount);
        setText(res.textCount);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);

          navigate("/");
        }
      });
  };

  const cancelEvent = () => {
    EventAction.cancelEvent(id)
      .then((res) => {
        showSuccessToast("Event Cancelled");
        setTimeout(() => {
          toast.dismiss();
          navigate("/getStarted");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);

          navigate("/");
        }
      });
  };

  const hideNumber = (phone) => {
    if (isNotEmpty(phone) && phone.length > 9) {
      let prefixLength = 6;
      let suffixLength = 2;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;

      return formattedPhone;
    } else {
      return phone;
    }
  };
  const hideEmail = (email) => {
    if (isNotEmpty(email)) {
      let emailSplit = email.split("@");
      let newEmail = emailSplit[0];
      let prefixLength = 1;
      let suffixLength = 1;

      let prefix = newEmail.substring(0, prefixLength);
      let suffix = newEmail.slice(-suffixLength);
      let nbStars = newEmail.length - (prefixLength + suffixLength);
      let formattedEmail =
        prefix + "*".repeat(nbStars) + suffix + "@" + emailSplit[1];

      return formattedEmail;
    } else {
      return email;
    }
  };

  useEffect(() => {
    if (!showSave) {
      setEventTime("");
      setEventStart("");
    }
  }, [showSave]);

  const disableButtons = () => {
    if (isValidDate(eventData?.eventStart)) {
      setShowGiftButton(true);
    } else {
      setShowGiftButton(false);
    }
    if (isNotEmpty(eventData?.giftCardType)) {
      setShowConfigureButton(true);
    } else {
      setShowConfigureButton(false);
    }
    if (
      isNotEmpty(eventData?.contriPerPerson) &&
      eventData?.contriPerPerson > 0
    ) {
      setAddParticipantButton(true);
    } else {
      setAddParticipantButton(false);
    }
  };

  const contributionsList = () => {
    showLoader();
    contributionAction
      .contributionList(id)
      .then((res) => {
        res.map((itemc) =>
          itemc?.eventParticipantId?.userId?._id === loggedInUserId
            ? setAlreadyContributed(true)
            : ""
        );
        setContributingList(res);
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Unauthorized User.") {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
          setIsSidebarVisible(false);
          navigate("/");
        }
      });
  };

  const handleConfirmOrder = async () => {
    var date = getDateLocal(eventData?.eventStart, eventData?.eventTime);
    if (new Date(`${date}`) < new Date(Date.now() + 12 * 60 * 60 * 1000))
      setShowConfirmOrder(true);
    else navigate(`/totalcontribution/${eventData?._id}`);
  };

  const handleUploadPic = async (e) => {
    let fieldValue = e;

    const imageFile = e;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
    };
    try {
      showLoader();
      const compressedFile = await imageCompression(imageFile, options);
      var fileName = await new File([compressedFile], compressedFile.name, {
        type: "image/jpeg",
      });
      fieldValue = fileName;

      setUploadedPic(fieldValue);
      hideLoader();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitImg = async () => {
    showLoader();
    try {
      if (haveValue(uploadedPic)) {
        const data = new FormData();
        data.append("file", uploadedPic);
        const res = await EventAction.updateEventImg(eventData?._id, data);
        // console.log(eventData)
        setEventData(res);
        showSuccessToast("Event Image Updated");
        // console.log(res)
        setUploadedPic(null);
        setshowEditPic(false);
      }
      hideLoader();
    } catch (err) {
      hideLoader();
      showErrorToast("Error");
      console.log(err);
    }
  };

  return (
    <>
      <div className="Organiser screen1 EventPartially">
        <div className="avatar-img d-flex">
          <img src={ShowImage(eventData?.photo)} alt="img" />
          {console.log(
            eventData,
            isLoggedInUserId(eventData?.createdBy?._id),
            "akhdaksljdkslajdaskljdaskldjs",
            eventData?.isConfirmed != "true",
            "CHECK CAMERA CONDITION HERE"
          )}
          {
            isLoggedInUserId(eventData?.createdBy?._id) &&
              eventData?.isConfirmed != true && (
                // <div className=''>
                <Button
                  onClick={(e) => setshowEditPic(true)}
                  className="bg-transparent border-0 p-0 m-0 camera_svg"
                >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="32" cy="32" r="32" fill="#E31B53" />
                    <path
                      d="M36.1654 32.8332C36.1654 33.9382 35.7264 34.998 34.945 35.7794C34.1636 36.5609 33.1038 36.9998 31.9987 36.9998C30.8936 36.9998 29.8338 36.5609 29.0524 35.7794C28.271 34.998 27.832 33.9382 27.832 32.8332C27.832 31.7281 28.271 30.6683 29.0524 29.8869C29.8338 29.1055 30.8936 28.6665 31.9987 28.6665C33.1038 28.6665 34.1636 29.1055 34.945 29.8869C35.7264 30.6683 36.1654 31.7281 36.1654 32.8332Z"
                      fill="white"
                    />
                    <path
                      d="M21.9993 25.3333C21.1153 25.3333 20.2674 25.6845 19.6423 26.3096C19.0172 26.9348 18.666 27.7826 18.666 28.6667V38.6667C18.666 39.5507 19.0172 40.3986 19.6423 41.0237C20.2674 41.6488 21.1153 42 21.9993 42H41.9994C42.8834 42 43.7313 41.6488 44.3564 41.0237C44.9815 40.3986 45.3327 39.5507 45.3327 38.6667V28.6667C45.3327 27.7826 44.9815 26.9348 44.3564 26.3096C43.7313 25.6845 42.8834 25.3333 41.9994 25.3333H40.046C39.162 25.3331 38.3143 24.9818 37.6893 24.3567L36.3094 22.9767C35.6844 22.3515 34.8367 22.0002 33.9527 22H30.046C29.162 22.0002 28.3143 22.3515 27.6893 22.9767L26.3093 24.3567C25.6844 24.9818 24.8367 25.3331 23.9527 25.3333H21.9993ZM22.8327 28.6667C22.6117 28.6667 22.3997 28.5789 22.2434 28.4226C22.0871 28.2663 21.9993 28.0543 21.9993 27.8333C21.9993 27.6123 22.0871 27.4004 22.2434 27.2441C22.3997 27.0878 22.6117 27 22.8327 27C23.0537 27 23.2657 27.0878 23.4219 27.2441C23.5782 27.4004 23.666 27.6123 23.666 27.8333C23.666 28.0543 23.5782 28.2663 23.4219 28.4226C23.2657 28.5789 23.0537 28.6667 22.8327 28.6667ZM37.8327 32.8333C37.8327 34.3804 37.2181 35.8642 36.1241 36.9581C35.0302 38.0521 33.5464 38.6667 31.9993 38.6667C30.4523 38.6667 28.9685 38.0521 27.8746 36.9581C26.7806 35.8642 26.166 34.3804 26.166 32.8333C26.166 31.2862 26.7806 29.8025 27.8746 28.7085C28.9685 27.6146 30.4523 27 31.9993 27C33.5464 27 35.0302 27.6146 36.1241 28.7085C37.2181 29.8025 37.8327 31.2862 37.8327 32.8333Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              )
            // </div>
          }
        </div>
        <Sparkle />
        <h4>
          <span>{eventData?.name}</span>'s <br></br>
          {eventData?.occasionId} Celebration
        </h4>

        <div className="user-name-email-bar">
          <p> {"+" + hideNumber(eventData?.phone)} </p>
          <span>|</span> {hideEmail(eventData?.email)}
        </div>
        <p className="message-description"> {eventData?.eventDescription} </p>
        <h6 className="timetitle">Time to set up the event.</h6>
        <EventPartiallyList enableAutoScroll={true} eventData={eventData} />
        <div className="organiser-mainboxes">
          <div id="DateTimeBox" className="boxwithnumeric-block">
            <div className="numericbar">1</div>
            <div className="boxwithnumeric">
              <DateTimeBoxOuter
                eventData={eventData}
                errors={errors}
                date={eventStart}
                time={eventTime}
                handleChangeDate={(e) => {
                  handleChangeDat(e.toDateString());
                }}
                handleChangeTime={(e) => {
                  handleChangeTim(e);
                }}
                handleShowSaveButton={(e) => setShowSave(e)}
                showSave={showSave}
              />
              <div className="savebtn">
                {eventData && !eventData?.isConfirmed ? (
                  haveValue(showSave) && showSave ? (
                    <>
                      <Link className="btn-pink" onClick={updateDateAndTime}>
                        <svg
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.33333 1.5V4.33333C4.33333 4.80004 4.33333 5.0334 4.42416 5.21166C4.50406 5.36846 4.63154 5.49594 4.78834 5.57584C4.9666 5.66667 5.19996 5.66667 5.66667 5.66667H11.3333C11.8 5.66667 12.0334 5.66667 12.2117 5.57584C12.3685 5.49594 12.4959 5.36846 12.5758 5.21166C12.6667 5.0334 12.6667 4.80004 12.6667 4.33333V2.33333M12.6667 16.5V11.1667C12.6667 10.7 12.6667 10.4666 12.5758 10.2883C12.4959 10.1315 12.3685 10.0041 12.2117 9.92416C12.0334 9.83333 11.8 9.83333 11.3333 9.83333H5.66667C5.19996 9.83333 4.9666 9.83333 4.78834 9.92416C4.63154 10.0041 4.50406 10.1315 4.42416 10.2883C4.33333 10.4666 4.33333 10.7 4.33333 11.1667V16.5M16 6.77124V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V5.5C1 4.09987 1 3.3998 1.27248 2.86502C1.51217 2.39462 1.89462 2.01217 2.36502 1.77248C2.8998 1.5 3.59987 1.5 5 1.5H10.7288C11.1364 1.5 11.3402 1.5 11.5321 1.54605C11.7021 1.58688 11.8647 1.65422 12.0138 1.7456C12.182 1.84867 12.3261 1.9928 12.6144 2.28105L15.219 4.88562C15.5072 5.17387 15.6513 5.318 15.7544 5.48619C15.8458 5.63531 15.9131 5.79789 15.9539 5.96795C16 6.15976 16 6.36358 16 6.77124Z"
                            stroke="white"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Save
                      </Link>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div id="AddGiftBox" className="boxwithnumeric-block">
            <div className="numericbar">2</div>
            <div className="boxwithnumeric">
              <AddGiftBox
                id={id}
                event={eventData}
                disable={!showGiftButton}
                contributions={contributingList}
              />
            </div>
          </div>

          <div id="MoneyCollectBox" className="boxwithnumeric-block">
            <div className="numericbar">3</div>
            <div className="boxwithnumeric">
              <MoneyCollectBox
                event={eventData}
                id={id}
                disable={!showConfigureButton}
              />
            </div>
          </div>
          {eventData?.isSolo ? (
            <></>
          ) : (
            <div id="AddParticipant" className="boxwithnumeric-block">
              <div className="numericbar">4</div>
              <div className="boxwithnumeric">
                <AddParticipant
                  id={id}
                  onUpdate={(e) => handleUpdate(e)}
                  event={eventData}
                  disable={!addParticipantButton}
                  reloadEvent={reloadEvent}
                />
              </div>
            </div>
          )}

          <div id="AddTestimonialBox" className="boxwithnumeric-block">
            <div className="numericbar">{eventData?.isSolo ? "4" : "5"}</div>
            <div className="boxwithnumeric">
              <AddTestimonialBox
                alreadyContributed={alreadyContributed}
                testimonials={testimonialList}
                audios={audios}
                photos={photos}
                videos={videos}
                gif={gif}
                text={text}
                event={eventData}
                loadEvent={viewEvent}
              />
            </div>
          </div>
          {isLoggedInUserId(eventData?.createdBy?._id) ? (
            <>
              {
                // eventData?.totalContri >= eventData?.giftCard?.minimumAmount && !eventData?.isConfirmed ?
                !eventData?.isConfirmed ? (
                  <div id="ConfirmYourOrder" className="boxwithnumeric-block">
                    <div className="numericbar">
                      {eventData?.isSolo ? "5" : "6"}
                    </div>
                    <div className="boxwithnumeric">
                      <div className="AddParticipant">
                        <div className="participant-head">
                          <div class="headleft">
                            <div className="customiconbrand">
                              <BsCardChecklist />
                            </div>
                            <div class="participant-detail">
                              <h4>Order Confirmation</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <Link to={`/totalcontribution/${eventData?._id}`} className='btn-pink'> Confirm Your Order</Link> */}
                      <button
                        disabled={
                          !haveValue(eventData?.eventStart) &&
                          !haveValue(eventData?.eventTime)
                        }
                        className="btn-pink"
                        onClick={handleConfirmOrder}
                      >
                        {" "}
                        Proceed To Checkout
                      </button>
                    </div>
                  </div>
                ) : (
                  <div id="ConfirmYourOrder" className="boxwithnumeric-block">
                    <div className="numericbar">
                      {eventData?.isSolo ? "5" : "6"}
                    </div>
                    <div className="boxwithnumeric">
                      <div className="AddParticipant">
                        <div className="participant-head">
                          <div class="headleft">
                            <div className="customiconbrand">
                              <BsCardChecklist />
                            </div>
                            <div class="participant-detail">
                              <h4>Order Confirmation</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button disabled className="btn-pink" type="submit">
                        Proceed To Checkout
                      </button>
                    </div>
                  </div>
                )
              }

              <div className="view_bottom">
                <Link
                  className={eventData?.isConfirmed ? "btn-pink" : "btngray"}
                  to={`/recipient/${id}`}
                >
                  Recipient view
                </Link>

                {!eventData?.isConfirmed ? (
                  <EventCancelState
                    handleClickCancel={(e) => EventhandleShow()}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>

      <Modal
        className="Createevent-modal dateadded-popup"
        show={showdatetimeadded}
        onHide={handleClosedateadded}
      >
        <Modal.Body>
          <div className="dateaddedbar mb-0">
            <Success />
            <h4>
              {eventStart} {eventTime}
            </h4>
            <p className="mb-0">Next Step</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btngray" onClick={handleClosedateadded}>
            Continue
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="Createevent-modal fixamountpopup dateadded-popup"
        show={showContributionSetting}
        onHide={handleClosedateadded}
      >
        <Modal.Header closeButton>Money Contribution Settings</Modal.Header>
        <Modal.Body>
          {eventData?.contriType === "fixed" ? (
            <>
              <div className="FixedAmount">
                <img src="/images/budget.png" alt="icon" />
                <h4>Fixed Amount</h4>
                <p>All Participants pay the same amount.</p>
                <Form.Group className="fixform" controlId="formBasicEmail">
                  <Form.Label>Enter per person contribution</Form.Label>
                  <div className="amount-type">
                    <Form.Control
                      type="number"
                      placeholder={getTotalContri(eventData?.contriPerPerson)}
                    />
                    <span className="perperson"> / person</span>
                  </div>
                </Form.Group>
                <div className="note-error">
                  <AiOutlineInfoCircle />
                  <p>
                    Cannot update this as the contributions have already
                    started.
                  </p>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {eventData?.contriType === "any" ? (
            <>
              <div className="FixedAmount">
                <img src="/images/budget.png" alt="icon" />
                <h4>Any Amount</h4>
                {haveValue(eventData?.minimumContriAmount) ? (
                  <>
                    <Form.Group className="fixform" controlId="formBasicEmail">
                      <Form.Label>Minimum Contribution</Form.Label>
                      <div className="amount-type">
                        <Form.Control
                          type="number"
                          disabled
                          placeholder={getTotalContri(
                            eventData?.minimumContriAmount
                          )}
                        />
                        <span className="perperson"> / person</span>
                      </div>
                    </Form.Group>
                  </>
                ) : (
                  ""
                )}
                <div className="note-error">
                  <AiOutlineInfoCircle />
                  <p>
                    Cannot update this as the contributions have already
                    started.
                  </p>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className="Createevent-modal dateadded-popup cancelevent-modal Eventcannot-modal"
        show={EventCantshow}
        onHide={handleClosedateadded}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="del-icon">
            <img src="/images/del.png" alt="delete-icon" />
          </div>
          <h4>This event cannot be cancelled</h4>
          <p>
            Since people have contributed towards the event, we cannot cancel
            this. If you still want to cancel, please reach us at{" "}
            <a href="mailto:info@wegroup4.com">info@wegroup4.com</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={handleClosedateadded}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="Createevent-modal dateadded-popup cancelevent-modal"
        show={CancelEventshow}
        onHide={handleClosedateadded}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="del-icon">
            <img src="/images/del.png" alt="delete-icon" />
          </div>
          <h4>Cancel this event?</h4>
          <p>
            Are you sure you want to cancel this event? This action cannot be
            undone.
          </p>
        </Modal.Body>
        <Modal.Footer>
          {haveValue(eventData?.totalContri) && eventData?.totalContri > 0 ? (
            <Button
              className="btn-pink btn-red"
              onClick={EventCantsethandleShow}
            >
              Yes, Cancel this event
            </Button>
          ) : (
            <Button className="btn-pink btn-red" onClick={cancelEvent}>
              Yes, Cancel this event
            </Button>
          )}
          <Button className="btngray" onClick={handleClosedateadded}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="Createevent-modal"
        show={showConfirmOrder}
        onHide={setShowConfirmOrder}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Delivery of your order may get delayed upto 12 hrs. You can expect delivery of your chosen gift for ${
            eventData?.name
          }'s ${eventData?.occasionId} on or before ${new Date(
            new Date(`${eventStart} ${eventTime}`).getTime() +
              12 * 60 * 60 * 1000
          )
            .toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            .replace("at", "by")}`}
        </Modal.Body>
        <Modal.Footer>
          <Link
            type="button"
            to={`/totalcontribution/${eventData?._id}`}
            className="btn-pink"
          >
            Continue
          </Link>
        </Modal.Footer>
      </Modal>

      <Modal
        className="Createevent-modal"
        show={showEditPic}
        onHide={(e) => setshowEditPic(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadImage
            handleChangeIcon={(e) => handleUploadPic(e)}
            name={`${eventData?.name}`}
            editProfile={true}
            icon={uploadedPic}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngray" onClick={handleSubmitImg}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Organiser;
