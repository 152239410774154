import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import UserPerviewList from "../../UserPerviewList";
import { haveValue, usersInEvent } from "../../../utils/helpers";
import { AuthContext } from "../../../Context/AuthContext";
import eventService from "../../../Actions/event.service";
import Switch from "react-switch";

function AddTestimonialBox(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [eventId, setEventId] = useState("");
  const {
    testimonials,
    videos,
    audios,
    photos,
    gif,
    text,
    event,
    alreadyContributed,
    loadEvent,
  } = props;
  const { isLoggedInUserId } = useContext(AuthContext);

  const [usersData, setUsersData] = useState([]);
  const [isUserLogged, setIsUserLogged] = useState(false);

  useEffect(() => {
    setEventId(id);
  }, []);

  useEffect(() => {
    let userListData = usersInEvent(testimonials);
    setUsersData(userListData);
  }, [testimonials]);
  useEffect(() => {
    checkUserLogged();
  }, [event]);

  const checkUserLogged = async () => {
    const temp = await isLoggedInUserId(event?.createdBy?._id);
    setIsUserLogged(temp);
  };

  const handleSwitch = (state) => {
    const payload = {
      _id: event?._id,
      freeTestimonials: state,
    };
    eventService
      .updateEventById(payload)
      .then((res) => {
        loadEvent();
      })
      .catch((err) => {
        console.log(err);
        // hideLoader();
      });
  };

  return (
    <>
      <div className="AddParticipant AddTestimonialBox">
        {testimonials?.length > 0 ? (
          <>
            <div className="participant-head c">
              <div className="headleft">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="32" height="32" rx="16" fill="#E6F4D7" />
                  <path
                    d="M18.6667 13.3333V11.0004C18.6667 10.446 18.6667 10.1687 18.5499 9.99832C18.4478 9.84946 18.2898 9.74834 18.1118 9.71806C17.9082 9.68341 17.6565 9.79959 17.153 10.032L11.2393 12.7613C10.7903 12.9686 10.5658 13.0722 10.4014 13.2329C10.256 13.375 10.1451 13.5484 10.077 13.7399C10 13.9565 10 14.2038 10 14.6983V18M19 17.6667H19.0067M10 15.4667L10 19.8667C10 20.6134 10 20.9868 10.1453 21.272C10.2732 21.5229 10.4771 21.7268 10.728 21.8547C11.0132 22 11.3866 22 12.1333 22H19.8667C20.6134 22 20.9868 22 21.272 21.8547C21.5229 21.7269 21.7268 21.5229 21.8547 21.272C22 20.9868 22 20.6134 22 19.8667V15.4667C22 14.7199 22 14.3466 21.8547 14.0614C21.7268 13.8105 21.5229 13.6065 21.272 13.4787C20.9868 13.3333 20.6134 13.3333 19.8667 13.3333L12.1333 13.3333C11.3866 13.3333 11.0132 13.3333 10.728 13.4787C10.4771 13.6065 10.2732 13.8105 10.1453 14.0614C10 14.3466 10 14.7199 10 15.4667ZM19.3333 17.6667C19.3333 17.8508 19.1841 18 19 18C18.8159 18 18.6667 17.8508 18.6667 17.6667C18.6667 17.4826 18.8159 17.3333 19 17.3333C19.1841 17.3333 19.3333 17.4826 19.3333 17.6667Z"
                    stroke="#4F7A21"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="participant-detail">
                  <h4>Wishes</h4>
                </div>
              </div>
            </div>
            <ul className="testimonails-listing">
              <li>
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 4.93137C21 4.32555 21 4.02265 20.8802 3.88238C20.7763 3.76068 20.6203 3.69609 20.4608 3.70865C20.2769 3.72312 20.0627 3.93731 19.6343 4.36569L16 8L19.6343 11.6343C20.0627 12.0627 20.2769 12.2769 20.4608 12.2914C20.6203 12.3039 20.7763 12.2393 20.8802 12.1176C21 11.9774 21 11.6744 21 11.0686V4.93137Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H11.2C12.8802 1 13.7202 1 14.362 1.32698C14.9265 1.6146 15.3854 2.07354 15.673 2.63803C16 3.27976 16 4.11984 16 5.8V10.2C16 11.8802 16 12.7202 15.673 13.362C15.3854 13.9265 14.9265 14.3854 14.362 14.673C13.7202 15 12.8802 15 11.2 15H5.8C4.11984 15 3.27976 15 2.63803 14.673C2.07354 14.3854 1.6146 13.9265 1.32698 13.362C1 12.7202 1 11.8802 1 10.2V5.8Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{videos}</span> Videos
              </li>
              <li>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.00002 20.8174C3.6026 21 4.41649 21 5.8 21H16.2C17.5835 21 18.3974 21 19 20.8174M3.00002 20.8174C2.87082 20.7783 2.75133 20.7308 2.63803 20.673C2.07354 20.3854 1.6146 19.9265 1.32698 19.362C1 18.7202 1 17.8802 1 16.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H16.2C17.8802 1 18.7202 1 19.362 1.32698C19.9265 1.6146 20.3854 2.07354 20.673 2.63803C21 3.27976 21 4.11984 21 5.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C19.2487 20.7308 19.1292 20.7783 19 20.8174M3.00002 20.8174C3.00035 20.0081 3.00521 19.5799 3.07686 19.2196C3.39249 17.6329 4.63288 16.3925 6.21964 16.0769C6.60603 16 7.07069 16 8 16H14C14.9293 16 15.394 16 15.7804 16.0769C17.3671 16.3925 18.6075 17.6329 18.9231 19.2196C18.9948 19.5799 18.9996 20.0081 19 20.8174M15 8.5C15 10.7091 13.2091 12.5 11 12.5C8.79086 12.5 7 10.7091 7 8.5C7 6.29086 8.79086 4.5 11 4.5C13.2091 4.5 15 6.29086 15 8.5Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{photos}</span> Photos
              </li>
              <li>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  t="1569683725598"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  height="26"
                  width="26"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs></defs>
                  <path d="M551.5 490.5H521c-4.6 0-8.4 3.7-8.4 8.4V720c0 4.6 3.7 8.4 8.4 8.4h30.5c4.6 0 8.4-3.7 8.4-8.4V498.9c-0.1-4.6-3.8-8.4-8.4-8.4zM477.3 600h-88.1c-4.6 0-8.4 3.7-8.4 8.4v23.8c0 4.6 3.7 8.4 8.4 8.4h47.6v0.7c-0.6 29.9-23 49.8-56.5 49.8-39.2 0-63.6-30.7-63.6-81.4 0-50.1 23.9-80.6 62.3-80.6 28.1 0 47.5 13.5 55.4 38.3l0.9 2.8h49.2l-0.7-4.6C475.9 515.9 434.7 484 379 484c-68.8 0-113 49.4-113 125.9 0 77.5 43.7 126.1 113.6 126.1 64.4 0 106-40.3 106-102.9v-24.8c0-4.6-3.7-8.3-8.3-8.3z"></path>
                  <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM602 137.8L790.2 326H602V137.8zM792 888H232V136h302v216c0 23.2 18.8 42 42 42h216v494z"></path>
                  <path d="M608.2 727.8h32.3c4.6 0 8.4-3.7 8.4-8.4v-84.8h87.8c4.6 0 8.4-3.7 8.4-8.4v-25.5c0-4.6-3.7-8.4-8.4-8.4h-87.8v-58.9h96.8c4.6 0 8.4-3.7 8.4-8.4v-26.8c0-4.6-3.7-8.4-8.4-8.4H608.2c-4.6 0-8.4 3.7-8.4 8.4v221.1c0 4.8 3.8 8.5 8.4 8.5z"></path>
                </svg>
                <span>{gif}</span> Gifs
              </li>
              <li>
                <svg
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 16V3.58888C7 2.73166 7 2.30306 7.18054 2.04492C7.33819 1.81952 7.58173 1.669 7.85384 1.6288C8.16546 1.58276 8.54882 1.77444 9.31554 2.1578L13 4.00003M7 16C7 17.6569 5.65685 19 4 19C2.34315 19 1 17.6569 1 16C1 14.3432 2.34315 13 4 13C5.65685 13 7 14.3432 7 16Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{audios}</span> Audio
              </li>
              <li>
                <svg
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 16V3.58888C7 2.73166 7 2.30306 7.18054 2.04492C7.33819 1.81952 7.58173 1.669 7.85384 1.6288C8.16546 1.58276 8.54882 1.77444 9.31554 2.1578L13 4.00003M7 16C7 17.6569 5.65685 19 4 19C2.34315 19 1 17.6569 1 16C1 14.3432 2.34315 13 4 13C5.65685 13 7 14.3432 7 16Z"
                    stroke="#344054"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>{text}</span> Text
              </li>
            </ul>
            <UserPerviewList
              usersData={usersData}
              eventData={event}
              navigateTo={`/testimoniallist/${event?._id}`}
            />
          </>
        ) : (
          <>
            <div className="participant-avatar">
              <img src="/images/Little.png" alt="img" />
            </div>
          </>
        )}
        {isUserLogged ? (
          <>
            <div className="savebtn">
              <Button
                onClick={() => navigate(`/testimoniallist/${eventId}`)}
                className="btn-pink"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.0013 1.16667V12.8333M1.16797 7H12.8346"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Send Wishes
              </Button>
              {testimonials?.length > 0 ? (
                <Button
                  onClick={() => navigate(`/testimoniallist/${eventId}`)}
                  className="btngray"
                >
                  View all Wishes
                </Button>
              ) : (
                ""
              )}
            </div>
            {!event?.isSolo && (
              <div className="togglebar">
                <Switch
                  onChange={(prevState) => handleSwitch(prevState)}
                  checked={
                    haveValue(event?.freeTestimonials) &&
                    event?.freeTestimonials
                  }
                  offColor="#f2f4f7"
                  onColor="#e31854"
                  onHandleColor="#ffffff"
                  offHandleColor="#ffffff"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={24}
                  width={44}
                  className="react-switch"
                  id="material-switch"
                />
                <p>
                  Allow participants to contribute wishes without contribution
                </p>
              </div>
            )}
          </>
        ) : alreadyContributed ||
          (haveValue(event?.freeTestimonials) && event?.freeTestimonials) ? (
          <>
            <div className="savebtn">
              <Button
                onClick={() => navigate(`/testimoniallist/${eventId}`)}
                className="btn-pink"
              >
                {/* <Button> */}
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.0013 1.16667V12.8333M1.16797 7H12.8346"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Send Wishes
              </Button>
              {testimonials?.length > 0 ? (
                <Button
                  onClick={() => navigate(`/testimoniallist/${eventId}`)}
                  className="btngray"
                >
                  View all Wishes
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <div className="savebtn">
              <Button
                disabled
                // onClick={() => navigate(`/testimoniallist/${eventId}`)}
                className="btn-pink"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.0013 1.16667V12.8333M1.16797 7H12.8346"
                    stroke="white"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Send Wishes
              </Button>
              {testimonials?.length > 0 ? (
                <Button
                  onClick={() => navigate(`/testimoniallist/${eventId}`)}
                  className="btngray"
                >
                  View all Wishes
                </Button>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddTestimonialBox;
